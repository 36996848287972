import { CSSProperties } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

import BTC from '../../images/currency/BTC.png';
import CRC from '../../images/currency/CRC.png';
import RUB from '../../images/currency/RUB.png';
import USD from '../../images/currency/USD.png';
import USDT from '../../images/currency/USDT.png';

interface MonetaryInputProps {
    label?: string,
    value?: string,
    currencyCode?: string
    className?: string
    prefix?: string,
    iconStyles?: CSSProperties
    containerStyles?: CSSProperties
    inputStyles?: CSSProperties
    onChange?: ((v: string) => void),
}

export const getCurrencyIcon = (code?: string): string => {
    switch (code) {
        case "USDT":return USDT
        case "CRC": return CRC
        case "RUB": return RUB
        case "BTC": return BTC
        default:
            return USD;
    }
}

const getDecimalScale = (currencyCode?: string): number => {
    switch (currencyCode) {
        case "BTC":
            return 8
        case "USDT":
            return 4
        default:
            return 2
    }
}


export const MonetaryInput = (props: MonetaryInputProps) => {
    const onChange = (values: NumberFormatValues): void => {
        props.onChange && props.onChange(values.value || "")
    }
    const icon = getCurrencyIcon(props.currencyCode)
    return(
        <div className={props.className} style={{position: "relative", ...props.containerStyles}}>
            <img src={icon} alt={props.currencyCode} style={props.iconStyles || {position: "absolute", top: "8px", left: "3px", width: "18px"}} />
            <NumericFormat
                style={{width: "100%", paddingLeft: "26px", boxSizing: "border-box", ...props.inputStyles}}
                thousandSeparator=" "
                allowNegative
                fixedDecimalScale
                decimalScale={getDecimalScale(props.currencyCode)}
                allowedDecimalSeparators={["."]}
                onValueChange={onChange}
                placeholder={'0.' + '0'.repeat(getDecimalScale(props.currencyCode))}
                value={props.value} />
        </div>
    )
}