import './Transactions.css';

import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { DeleteForever, MoreVert } from '@mui/icons-material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {
    Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Dropdown,
    LinearProgress, Menu, MenuButton, MenuItem, ModalDialog
} from '@mui/joy';
import Modal from '@mui/joy/Modal';

import {
    deleteRecordApiV2TransactionRecordIdDelete as deleteRecordV2,
    getAllTransactionsApiV2TransactionListGet as getTransactionsV2, JournalRecordItem
} from '../../client';
import { Pagination } from '../../schema';
import { formatMoneyAmount, getToken } from '../../utils';

const TransactionList = (props: {pagination: Pagination}) => {
    const [records, setRecords] = useState<JournalRecordItem[]>([])
    const [deleteId, setDeleteId] = useState<number>(-1)
    const [isDeleting, setIsDeleting] = useState<boolean>(false)


    const loadRecords = () => {
        getTransactionsV2(
            {
                token: localStorage.getItem("token") || "",
                offset: props.pagination.offset,
                limit: props.pagination.limit,
            }
        ).then(data => {
            setRecords(data)
        })
    }

    useEffect(() => {
        loadRecords()
    }, [])

    if(records.length === 0){
        return <LinearProgress />
    }

    const deleteRecord = () => {
        setIsDeleting(true)
        deleteRecordV2({token: getToken(), recordId: deleteId}).then(_ => {
            setDeleteId(-1)
            setIsDeleting(false)
            loadRecords()
        })
    }

    return (
        <div className="Transaction-List">
            {records.map(record => (
                <div className="Journal-Record">
                    <div className="Journal-Record-Header">
                        <div className="Journal-Record-Date">
                            <span>{DateTime.fromISO(record.created_at).toFormat("LLL dd yyyy")}</span>
                        </div>
                        <div className="Journal-Record-Description">
                            <b>Description: </b> <br />
                            <span>{record.description}</span>
                        </div>
                        <div className="JournalRecord-Total">
                            <div className="Journal-Record-Total-Domestic">{formatMoneyAmount(record.domestic_total)}</div>
                            {!record.domestic && <div className="Journal-Record-Total-Currency">{formatMoneyAmount(record.currency_total)}</div>}
                        </div>
                    </div>
                    <div className="PostingRow-Controls">
                        <Dropdown>
                            <MenuButton><MoreVert fontSize='small' /></MenuButton>
                            <Menu>
                                <MenuItem color="danger" onClick={_ => setDeleteId(record.record_id || -1)}>
                                    <DeleteForever /> Delete
                                </MenuItem>
                            </Menu>
                        </Dropdown>
                    </div>
                    <Divider> Splits </Divider>
                    {record.splits.map(s => (
                        <div className={"Split-Record " + (s.debit ? "" : "Split-Credit")}>
                            <span>{ s.account_name }</span>
                            <span>{ formatMoneyAmount(s.amount) }</span>
                        </div>
                    ))}
                </div>
            ))}
            <Modal open={deleteId !== -1} onClose={_ => setDeleteId(-1)}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <DialogTitle>
                        <WarningRoundedIcon /> Confirmation
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        Are you sure you want to delete this transaction?
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isDeleting} variant='solid' color="danger" onClick={() => deleteRecord()}>
                            Delete Transaction
                        </Button>
                        {isDeleting && <CircularProgress />}
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </div>
    )
}


export const Transactions = () => {

    const [pag, setPag] = useState<Pagination>(new Pagination(0, 100))

    return (
        <>
            <h1>Transactions</h1>
            <div className="Page-Tabs">
                <Link to="/transaction/add/split">Add Transaction</Link>
            </div>
            <TransactionList pagination={pag} />
        </>
    )
}