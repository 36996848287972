import './Header.css';

import { SyntheticEvent, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';

import { CurrencyExchange, Logout, Upload } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import SyncIcon from '@mui/icons-material/Sync';
import { Avatar, Dropdown, Menu, MenuButton, MenuItem, Modal } from '@mui/joy';
import { CircularProgress } from '@mui/material';

import {
    CurrencyModel, getCurrenciesApiV2CurrencyListGet as getCurrencies,
    setPriceApiV2CurrencyPricePost as setPriceV2
} from '../../client';
import { getToken } from '../../utils';

interface CurrencyExchangeModalProps {
    open: boolean
    onClose: () => void 
}

type ExtendedCurrencyModel =  CurrencyModel & {changed?: boolean, uploading?: boolean}


const CurrencyExchangeModal = (props: CurrencyExchangeModalProps) => {

    const [currencies, setCurrencies] = useState<ExtendedCurrencyModel[]>([])
   
    const loadItems = () => {
        getCurrencies({token: getToken()}).then(data => {
            setCurrencies(data.sort((a, b) => (a.id || 0) - (b.id || 0)))
        })
    }

    useEffect(() => {
        loadItems()
    }, [])

    const defaultItem = currencies.find(c => c.is_default === true)
    if(!defaultItem){
        return null
    }

    const changeItem = (currency_id: number, key: keyof ExtendedCurrencyModel, value: any) => {
        const itemId = currencies.findIndex(c => c.id === currency_id)
        setCurrencies([
            ...currencies.slice(0, itemId),
            {...currencies[itemId], [key]: value},
            ...currencies.slice(itemId + 1)
        ])
    }

    const handleUpload = (currency_id: number) => {
        const item = currencies.find((c => c.id === currency_id))
        if(!item) return;
        if(!item.exchange_rate) return;
        changeItem(currency_id, "uploading", true)
        setPriceV2({
            token: getToken(), 
            requestBody: {currency_id: currency_id, price: item.exchange_rate}
        }).then(_ => {
            loadItems()
        })
    }


    return (
        <Modal 
            open={props.open} 
            onClose={_ => props.onClose()} 
            sx={{
                display: "flex", 
                alignItems: "center", 
                justifyContent: "center"
            }}>
            <div className="Header-Modal">
                <h2>Exchange Rates</h2>
                {currencies.map((cur, i) => ( !cur.is_default &&
                    <div className="Exchange-Rate-Item">
                        <label>{cur.short_code}/{defaultItem.short_code}</label>
                        <NumericFormat 
                            value={cur.exchange_rate || ""}
                            placeholder='0.00000000'
                            decimalScale={8}
                            fixedDecimalScale
                            className='Exchange-Rate-Input'
                            onValueChange={(vs) => {
                                if(!cur.id) return
                                changeItem(cur.id, "exchange_rate", vs.value)
                                changeItem(cur.id, "changed", true)
                            }}
                            />
                        <div className="Exchange-Rate-BtnGroup">
                            {/* <button><CircularProgress size="24px" /></button> */}
                            <button disabled={!cur.changed} onClick={e => cur.id && handleUpload(cur.id)}>
                                {cur.uploading ? <CircularProgress size="24px" /> : <Upload />}
                            </button>
                            <button disabled={true}><SyncIcon /></button>
                        </div>
                    </div>
                ))}
                
            </div>
        </Modal>
    )
}


export const Header = () => {
    const [ menuItem, setMenuItem ] = useState<number>(-1)
    const navigate = useNavigate()


    const LogOutHandler = (e: SyntheticEvent) => {
        e.preventDefault()
        localStorage.removeItem("token")
        navigate("/signin")
    }

    return(
        <header>
            <div className="Header-Account">
                <Dropdown>
                    <MenuButton>
                        <Avatar size='md' />
                    </MenuButton>

                    <Menu placement='bottom-end' className='Header-Menu'>
                        <MenuItem> <StoreIcon /> Company </MenuItem>
                        <MenuItem> <PersonIcon /> Profile </MenuItem>
                        <MenuItem onClick={_ => setMenuItem(2)}> 
                            <CurrencyExchange /> Exchange Rates 
                        </MenuItem>
                        <MenuItem color='danger' onClick={LogOutHandler}> 
                            <Logout /> Logout 
                        </MenuItem>
                    </Menu>
                </Dropdown>
                <CurrencyExchangeModal open={menuItem === 2} onClose={() => setMenuItem(-1)} />
            </div>
        </header>
    )
}