import './StatementBody.css';

import { isEqual } from 'money-math';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { LinearProgress } from '@mui/joy';

import { StatementPart } from '../../client';
import { formatCurrency, formatMoneyAmount } from '../../utils';
import { StatementOptions } from './StatementOptions';

export const StatementBody = (props: {data: StatementPart[]}) => {

    const [zeroBalances, setZeroBalances] = useState<boolean>(false)
    const [showClosed, _] = useState<boolean>(false)

    if(props.data.length === 0){
        return <LinearProgress />
    }

    return (
        <div className="Statement-Body">
            
            <StatementOptions zeroBalances={zeroBalances} onChange={e => setZeroBalances(e.target.checked)} />

            {props.data?.map(part => (
            <div>
                <h3>{part.title} ({formatCurrency(part.total)})</h3>
                <table cellPadding={0} cellSpacing={0}>
                    {part.rows.map(row => {
                        if(isEqual(row.amount_domestic.amount || "0.00", "0.00") && !zeroBalances) return ""
                        if(row.closed && ! showClosed) return ""
                        return (
                            <tr>
                                <td className="Category-Name">
                                <Link to={"/account/" + row.account_id}>{row.account_name}</Link>
                                </td>
                                <td className="Category-Amount">
                                <span className="Category-Amount__Main">{formatMoneyAmount(row.amount_domestic)}</span>
                                {!row.domestic && <span className="Category-Amount__Currency">
                                    {formatMoneyAmount(row.amount_currency)}
                                </span>}
                                </td>
                            </tr>
                        )
                    })}
                </table>
            </div>
            ))}
        </div>
    )
}