import { DateTime } from 'luxon';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { newPosting, Posting } from './Schema';

interface TransactionFormState {
    created_at: string
    postings: Posting[]
    comment: string 
}

const getInitialState = (): TransactionFormState => {
    return {
        created_at: DateTime.now().toISODate(),
        postings: [],
        comment: "",
    }
}


export const TransactionSplitFormSclice = createSlice({
    name: "transactionSplitForm",
    initialState: getInitialState(),
    reducers: {
        setDate: (state, action: PayloadAction<string>) => {
            let date = DateTime.fromISO(action.payload)
            const now = DateTime.now()
            date = date.plus({hours: now.hour, minutes: now.minute, seconds: now.second})
            console.log(date.toISO())
            return {...state, created_at: action.payload}
        },
        addPosting: (state, action: PayloadAction<{debit: boolean}>) => {
            return {...state, postings: [...state.postings, newPosting(action.payload.debit)]}
        },
        changePosting: (state, action: PayloadAction<{id: string, key: keyof Posting, value?: string}>) => {
            const idx = state.postings.findIndex(p => p.posting_id === action.payload.id)
            const post = {...state.postings[idx], [action.payload.key]: action.payload.value}
            const postings = [...state.postings.slice(0, idx), post, ...state.postings.slice(idx+1)]
            return {...state, postings: postings}
        },
        deletePosting: (state, action: PayloadAction<{id: string}>) => {
            const idx = state.postings.findIndex(p => p.posting_id === action.payload.id)
            const postings = [...state.postings.slice(0, idx), ...state.postings.slice(idx + 1)]
            return {...state, postings: postings}
        },
        setComment: (state, action: PayloadAction<string>) => {
            return {...state, comment: action.payload}
        },
        resetForm: (state) => {
            return getInitialState()
        }
    }
})

export const {
    setDate,
    addPosting,
    changePosting,
    deletePosting,
    setComment,
    resetForm,
} = TransactionSplitFormSclice.actions