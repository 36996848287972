import { nanoid } from 'nanoid';

export interface Posting {
    posting_id: string
    account_id: number
    debit: boolean
    amount: string
}

export const newPosting = (debit: boolean): Posting => {
    return {
        posting_id: nanoid(),
        account_id: -1,
        debit: debit,
        amount: "0.00",
    }
}