import { BaseSyntheticEvent, SyntheticEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { signupApiV1UserSignupPost as signupPost } from '../../client';
import account from '../../images/account.png';
import lock from '../../images/lock.png';

class SignUpReq {
    username: string = ""
    password1: string = ""
    password2: string = ""
}

export const SignUpPage = () => {
    const [data, setData] = useState<SignUpReq>(new SignUpReq())
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const navigate = useNavigate()

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault()
        setLoading(true)
        setError("")
        if(data.password1 !== data.password2){
            setError("Passwords do not match")
            setInterval(() => setError(""), 3000)
        }
        await signupPost({
            requestBody: {username: data.username, password: data.password1}
        }).then(_ => {
            setLoading(false)
            navigate("/signin")
        }).catch(err => {
            setError(String(err))
            setInterval(() => setError(""), 10000)
            setLoading(false)
        })
    }

    const updateState = (key: keyof SignUpReq, e: BaseSyntheticEvent) => {
        setData({...data, [key]: e.target.value})
    }

    const formDisabled = ():boolean => {
        if(!data.username || !data.password1 || !data.password2){
            return true
        }
        if(data.password1 !== data.password2){
            return true
        }
        return false
    }

    return (
        <div className="Auth-Page">
            {error && <span className="Error-Msg">{error}</span>}
            <div className="Auth-Form">
                <h1>Sign Up</h1>
                <div className="Input-Group">
                    <img src={account} alt="" />
                    <input type="text" value={data.username} placeholder='Username' onChange={e => updateState("username", e)} />
                </div>
                <div className="Input-Group">
                    <img src={lock} alt="" />
                    <input type="password" value={data.password1} placeholder='Type password' onChange={e => updateState("password1", e)} />
                </div>
                <div className="Input-Group">
                    <img src={lock} alt="" />
                    <input type="password" value={data.password2} placeholder='Re-type password' onChange={e => updateState("password2", e)} />
                </div>
                <button disabled={formDisabled()} onClick={submit}>Submit</button>
                {loading && <span className="Loading-Msg">Loading...</span>}
                <span className="Form-Info">Already have an account? <Link to="/signin">Sign In</Link></span>
            </div>
        </div>
    )
}