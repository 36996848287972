import { createContext, Dispatch, useContext, useReducer } from 'react';

import { Delete } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { getCurrencyIcon, MonetaryInput } from '../../elements/FormElements/CurrencyInput';
import { FormWrapper } from '../../elements/FormElements/FormWrapper';

class CurrencyHelperContextClass {
    state: CurrencyHelperData = new CurrencyHelperData()
    dispatch?: Dispatch<Action>
}

const CurrencyHelperContext = createContext<CurrencyHelperContextClass | null>(null)

class CurrencyHelperData {
    mainCurrency: string = "USD"
    foreignCurrency: string = "CRC"
    mainCurrencyValue: string = ""
    foreignCurrencyValue: string = ""
    itemValue: string = ""
    items: string[] = []
}

type Action = 
    {type: "totalMainUpdate", payload: string} | 
    {type: "totalForeignUpdate", payload: string} |
    {type: "itemUpdate", payload: string} |
    {type: "addItemAmount"} |
    {type: "deleteItemAmount", payload: number}

const reducer = (state: CurrencyHelperData, action: Action): CurrencyHelperData => {
    switch (action.type){
        case "totalMainUpdate":
            return {...state, mainCurrencyValue: action.payload}
        case "totalForeignUpdate":
            return {...state, foreignCurrencyValue: action.payload}
        case 'itemUpdate':
            return {...state, itemValue: action.payload}
        case "addItemAmount":
            if(Number(state.itemValue) === 0){
                return state
            }
            const items = [...state.items, state.itemValue]
            return {...state, items: items, itemValue: "0"}
        case "deleteItemAmount": 
            const newItems = [...state.items.slice(0, action.payload), ...state.items.slice(action.payload + 1)]
            return {...state, items: newItems}
        default:
            return state
    }
}

const LocalCurrencyInput = (props: {currencyCode: string, value: string, onChange: (value: string) => void}) => {
    return (
        <MonetaryInput
            value={props.value}
            onChange={props.onChange}
            currencyCode={props.currencyCode}
            inputStyles={{border: "none", borderBottom: "1px solid black", fontSize: "18px", paddingLeft: "30px", outline: "none"}}
            iconStyles={{position: "absolute", width: "18px", top: "6px", left: "4px"}} />
    )
}

const ItemTag = (props: {value: string, idx: number}) => {
    const {state, dispatch} = useContext(CurrencyHelperContext)!
    const icon = getCurrencyIcon(state.foreignCurrency)
    const containerStyles = {backgroundColor: "#f3f3f3", padding: "7px", borderRadius: "10px", display: "flex", alignItems: "center"}
    return (
        <div className="ItemValue" style={containerStyles}>
            <img src={icon} alt="currencyIcon" style={{height: "18px"}} />
            <span>{props.value}</span>
            <button
                style={{padding: "1px", border: "none", cursor: "pointer"}}
                onClick={() => dispatch!({type: "deleteItemAmount", payload: props.idx})}><Delete sx={{fontSize: 18}} /></button>
        </div>
    )
}


const CurrencyHelperTotal = (props: {rate: number}) => {
    const {state, dispatch} = useContext(CurrencyHelperContext)!
    return (
        <>
        <h3>Check Total</h3>
        <div className="TotalInputs" style={{display: "flex", width: "100%", gap: "10px"}}>
            <LocalCurrencyInput currencyCode={state.mainCurrency} value={state.mainCurrencyValue} onChange={v => dispatch!({type: "totalMainUpdate", payload: v})} />
            <LocalCurrencyInput currencyCode={state.foreignCurrency} value={state.foreignCurrencyValue} onChange={v => dispatch!({type: "totalForeignUpdate", payload: v})} />
        </div>
        <span>Exchange Rate: {isNaN(props.rate) ? "-" : props.rate.toFixed(4)}</span>
        </>
    )
}

const CurrencyHelperResult = (props: {rate: number}) => {

    const {state} = useContext(CurrencyHelperContext)!
    const totalItems = state.items.reduce((prev, cur) => {return prev + Number(cur)}, 0)
    const remaining = (Number(state.foreignCurrencyValue) - Number(totalItems))
    const totalItemsDomestic = totalItems / props.rate
    const remainingDomestic = remaining / props.rate
    return (
        <div className="Items-Total">
            <h3>Result</h3>
            <div className="Items-Total-Block">
                <p>Total Currency: <b>{state.foreignCurrency} {totalItems.toFixed(2)}</b></p>
                <p>Remaining Currency: <b>{state.foreignCurrency} {remaining.toFixed(2)}</b></p>
                <p>Total Domestic: <b>{state.mainCurrency} {totalItemsDomestic.toFixed(2)}</b></p>
                <p>Remaining Domestic: <b>{state.mainCurrency} {remainingDomestic.toFixed(2)}</b></p>
            </div>
        </div>
    )
}

export const CurrencyHelper = () => {

    const [state, dispatch] = useReducer(reducer, new CurrencyHelperData())

    const rate = Number(state.foreignCurrencyValue) / Number(state.mainCurrencyValue)

    return (
        <CurrencyHelperContext.Provider value={{state: state, dispatch: dispatch}}>
        <FormWrapper style={{width: "400px", gap: "20px", display: "flex", flexDirection: "column"}}>
            <h2>Currency Expense</h2>
            <CurrencyHelperTotal rate={rate} />
            <h3>Items</h3>
            <div className="ForeignAmountInput" style={{position: "relative"}}>
                <LocalCurrencyInput currencyCode={state.foreignCurrency} value={state.itemValue} onChange={v => dispatch({type: "itemUpdate", payload: v})} />
                <button onClick={() => dispatch({type: "addItemAmount"})} style={{position: "absolute", top: "10%", right: "0", height: "80%", width: "40px", padding: 0, border: "none", background: "none", cursor: "pointer"}}><AddCircleOutlineIcon /></button>
            </div>
            <div className="ItemAmounts" style={{display: "flex", gap: "10px", flexWrap: "wrap"}}>
                {state.items.map((item, i) => <ItemTag value={item} idx={i} />)}
            </div>
            <CurrencyHelperResult rate={rate} />
        </FormWrapper>
        </CurrencyHelperContext.Provider>
    )
}