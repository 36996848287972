import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    AccountTypeListItem as AccItem, createAccountApiV1AccountPost as createAccount, CurrencyModel,
    getAccountTypesApiV1AccountTypesGet as getAccTypes,
    getCurrenciesApiV2CurrencyListGet as getCurrencies
} from '../../client';
import { FormInput, FormSelect } from '../../elements/FormElements/FormInput';
import { FormSubmit } from '../../elements/FormElements/FormSubmit';
import { FormWrapper } from '../../elements/FormElements/FormWrapper';
import { getToken } from '../../utils';

class AccData {
    accType: number = -1
    accName: string = ""
    accCurrency: number = -1
}

export const AccountAdd = () => {
    const [accTypes, setAccTypes] = useState<AccItem[]>([])
    const [data, setData] = useState<AccData>(new AccData())
    const [currencies, setCurrencies] = useState<CurrencyModel[]>([])
    const [error, setError] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    
    useEffect(() => {
        getAccTypes({token: getToken()}).then(res => setAccTypes(res))
    }, [])

    useEffect(() => {
        getCurrencies({token: getToken()}).then(res => {
            setCurrencies(res)
        })
    }, [])

    const onSubmit = (e: SyntheticEvent) => {
        e.preventDefault()
        setLoading(true)
        createAccount({token: getToken(), requestBody: {
            account_type: data.accType,
            name: data.accName,
            currency_id: data.accCurrency,
        }}).then(_ => {
            setLoading(false)
            navigate("/account")
        })
    }

    useEffect(() => {
        // Form Validation
        if(data.accType === -1){
            setError("Account type is not specified")
            return
        }
        if(data.accName === ""){
            setError("Account name is not specified")
            return
        }
        if(data.accCurrency === -1){
            setError("Currency is not specified")
            return
        }
        setError("")
    }, [data])

    return (
        <FormWrapper>
            <FormSelect label="Account Type" onChange={e => setData({...data, accType: Number(e.target.value)})} value={data.accType}>
                <option value="-1">Not Specified</option>
                {accTypes.map((item) => <option value={item.id}>{item.verbose_name}</option>)}
            </FormSelect>
            <FormInput label="Account Name" value={data.accName} onChange={e => setData({...data, accName: e.target.value})} />
            <FormSelect label="Currency" onChange={e => setData({...data, accCurrency: Number(e.target.value)})} value={data.accCurrency}>
                <option value="-1">Not Specified</option>
                {currencies.map(currency => <option value={currency.id as number}>{currency.short_code}</option>)}
            </FormSelect>
            <FormSubmit onSubmit={onSubmit} error={error} ready={error === ""} loading={loading} />
        </FormWrapper>
    )
}