import { add } from 'money-math';

import { Posting } from './Schema';

export const CalcTotal = (postings: Posting[], debit?: boolean): string => {
    if(postings.length === 0) return "0.00";
    let filteredPostings = postings
    if(debit !== undefined){
        filteredPostings = postings.filter(p => p.debit === debit)
    }
    if(filteredPostings.length === 0){
        return "0.00"
    }
    const amounts = filteredPostings.map(p => p.amount || "0.00")
    return amounts.reduce((total, amount) => add(total, amount))
}