import './IncomeStatement.css';

import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import {
    getIncomeStatementApiV2StatementIncomeGet as getStatement, StatementPart
} from '../../client';
import { formatCurrency, getToken } from '../../utils';
import { StatementBody } from '../StatementBody/StatementBody';

export const IncomeStatement = () => {
    const [data, setData] = useState<StatementPart[]>([])
    const [fromDate, setFromDate] = useState<DateTime>(DateTime.now().startOf("month"))
    const isCurrentMonth = fromDate.month === DateTime.now().month
    const netIncome = data.length > 0 ? Number(data[0].total) - Number(data[1].total) : 0
    useEffect(() => {
        getStatement({
            fromDate: fromDate.toISODate(),
            toDate: fromDate.plus({months: 1}).toISODate(),
            token: getToken(),
        }).then((res) => {
            setData(res)
        })
    }, [fromDate])
    return (
        <div className="Income-Statement">
            <h2>Income statement</h2>
            <div className="Statement-Period-Header">
                <button className="Period-Button" onClick={() => {setFromDate(fromDate.minus({month: 1}))}}>{"<"}</button>
                <span>{fromDate.monthShort} / {fromDate.year}</span>
                {!isCurrentMonth && <button className="Period-Button" onClick={() => {setFromDate(fromDate.plus({month: 1}))}}>{">"}</button>}
            </div>
            <StatementBody data={data} />
            <div className="Net-Income-Row">
                <h3>Net Income</h3>
                <span>{formatCurrency(netIncome)}</span>
            </div>
        </div>
    )
}