import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import App from './App';
import { store } from './app/store';
import { CommonTransactionForm } from './elements/CommonTransactionForm/CommonTransactionForm';
import { AccountAdd } from './pages/AccountAdd/AccountAdd';
import { AccountItem } from './pages/AccountItem/AccountItem';
import { Accounts } from './pages/Accounts/Accounts';
import { CreateCompanyPage } from './pages/AuthPages/CreateCompanyPage';
import { SignInPage } from './pages/AuthPages/SignInPage';
import { SignUpPage } from './pages/AuthPages/SignUpPage';
import { Dashboard } from './pages/DashboardPage/DashBoard';
import { TransactionAdd } from './pages/TransactionAdd/TransactionAdd';
import { TransactionItem } from './pages/TransactionItem/TransactionItem';
import { Transactions } from './pages/Transactions/Transactions';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/transaction",
        element: <Transactions />,
      },
      {
        path: "/transaction/:itemId",
        element: <TransactionItem />,
      },
      {
        path: "/transaction/add",
        element: <CommonTransactionForm />,
      },
      {
        path: "/transaction/add/split",
        element: <TransactionAdd />
      },
      {
        path: "/account",
        element: <Accounts />,
      },
      {
        path: "/account/:itemId",
        element: <AccountItem />,
      },
      {
        path: "/account/add",
        element: <AccountAdd />,
      }
    ]
  },
  {
    path: "/signin",
    element: <SignInPage />
  },
  {
    path: "/signup",
    element: <SignUpPage />
  },{
    path: "/create-company",
    element: <CreateCompanyPage />,
  }
])

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
