import './Accounts.css';

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
    getAccountsApiV1AccountListGet as getAccounts,
    GetAccountsApiV1AccountListGetResponse as GetAccountsResponse
} from '../../client';
import { getToken } from '../../utils';

export const Accounts = () => {

    const [accounts, setAccounts] = useState<GetAccountsResponse | null>(null)

    useEffect(() => {
        getAccounts({token: getToken()}).then(res => {
            setAccounts(res)
        })
    }, [])

    return(
        <>
            <h1>Accounts</h1>
            <div className="Page-Tabs">
                <Link to="/account/add">Add</Link>
            </div>
            <div className="Accounts-List">
                {accounts?.map(agroup => (
                    <div className="Accounts-Group">
                        <h2>{agroup.title}</h2>
                        {agroup.items?.map(a => (
                            <div className='Account-Item'>
                                <Link className='Edit-Link' to={"" + a.id}>✏️</Link>
                                <span>{a.name}</span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </>
    )
}