import './DashboardWidget.css';

import { ReactNode } from 'react';

export const DashboardWidget = (props: {children?: ReactNode}) => {
    return (
        <div className="Dashboard-Widget">
            {props.children}
        </div>
    )
}