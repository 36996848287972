import './AccountItem.css';

import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    AccountPeriod, getPostingsApiV2AccountAccountIdPostingsGet as getInfo
} from '../../client';
import { formatCurrency, getToken } from '../../utils';

export const AccountItem = () => {
    const clientId = useParams()
    const [data, setData] = useState<AccountPeriod[]>([])
    useEffect(() => {
        getInfo({accountId: Number(clientId.itemId), token: getToken()}).then(res => {
            setData(res)
        })
    }, [clientId])
    return(
        <div className="Account-Page">
            <table>
                {data?.map(period => (
                    <div className="Account-Period">
                        <h2>{period.title}</h2>
                        <h3>Opening balance: {formatCurrency(period.opening_balance)}</h3>
                        <table cellPadding={0} cellSpacing={0}>
                            {period.rows?.map(row => (
                                <tr>
                                    <td>
                                        {DateTime.fromISO(row.created_at).toFormat("LLL/d HH:mm")}
                                    </td>
                                    <td className='Amount-Cell Debit-Cell'>
                                        {Number(row.debit_amount) > 0 ? formatCurrency(row.debit_amount) : "-"}
                                    </td>
                                    <td className='Amount-Cell Credit-Cell'>
                                        {Number(row.credit_amount) > 0 ? formatCurrency(row.credit_amount) : "-"}
                                    </td>
                                    <td className="Balance-Cell">
                                        {formatCurrency(row.balance)}
                                    </td>
                                    <td>
                                        {row.comment}
                                    </td>
                                </tr>
                            ))}
                        </table>
                        <h3>Closing Balance: {formatCurrency(period.closing_balance)}</h3>
                    </div>
                ))}
            </table>
        </div>
    )
}