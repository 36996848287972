import { ChangeEventHandler, ReactNode } from 'react';

interface FormInputProps {
    label: string
    inputType?: string
    value?: string | number
    onChange?: ChangeEventHandler<HTMLInputElement>
}

export const BaseFormInput = (props: {label: string, children: ReactNode}) => {
    return (
        <div className="Form-Input">
            <label>{props.label}</label>
            {props.children}
        </div>
    )
}

export const FormInput = (props: FormInputProps) => {
    const inputType = props.inputType || "text"
    return (
        <BaseFormInput label={props.label}>
            <input type={inputType} value={props.value} onChange={props.onChange} />
        </BaseFormInput>
    )
}


interface FormInputSelectProps {
    label: string
    children?: ReactNode
    value?: number | string
    onChange?: ChangeEventHandler<HTMLSelectElement>
}

export const FormSelect = (props: FormInputSelectProps) => {
    return (
        <BaseFormInput label={props.label}>
            <select value={props.value} onChange={props.onChange}>
                {props.children}
            </select>
        </BaseFormInput>
    )
}