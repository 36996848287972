import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { joinCompanyApiV1CompanyJoinPost as joinPost } from '../../client';
import family from '../../images/family.png';

export const CreateCompanyPage = () => {
    const navigate = useNavigate()
    const [secret, setSecret] = useState<string>("")
    const submit = (e: SyntheticEvent) => {
        e.preventDefault()
        const token = localStorage.getItem("token")
        if(token === null){
            navigate("/signin")
            return
        }
        joinPost({requestBody: {secret: secret}, token: token}).then(
            res => navigate("/dashboard")
        ).catch(err => {
            
        })
    }

    return(
        <div className="Auth-Page">
            <div className="Auth-Form">
                <h1>Your family key</h1>
                <div className="Input-Group">
                    <img src={family} alt="" />
                    <input type="password" placeholder="Secret Key" value={secret} onChange={e => setSecret(e.target.value)} />
                </div>
                <button onClick={submit}>Submit</button>
            </div>
        </div>
    )
}