import { MoreVert } from '@mui/icons-material';
import { Dropdown, Menu, MenuButton, Switch, Typography } from '@mui/joy';

interface StatementOptionsProps {
    zeroBalances?: boolean
    onChange?: React.ChangeEventHandler<HTMLInputElement>
}


export const StatementOptions = (props: StatementOptionsProps) => {
    return (
    <div className="Dashboard-Widget-Options">
        <Dropdown>
            <MenuButton><MoreVert /></MenuButton>
            <Menu>
                <Typography
                    sx={{padding: "15px"}}
                    component="label"
                    endDecorator={<Switch sx={{ml: 1}} checked={props.zeroBalances} onChange={props.onChange} />}
                >
                    Show balances ≤ 0
                </Typography>
            </Menu>
        </Dropdown>
    </div>
    )
}