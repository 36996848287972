import './CommonTransactionForm.css';

import { DateTime } from 'luxon';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    AccountListGroup, getAccountsApiV1AccountListGet as getAccountsList,
    postTransactionApiV1TransactionPost as postTransaction
} from '../../client';
import { getToken } from '../../utils';
import { MonetaryInput } from '../FormElements/CurrencyInput';
import { FormInput, FormSelect } from '../FormElements/FormInput';
import { FormWrapper } from '../FormElements/FormWrapper';

class TransactionForm {
  date: DateTime = DateTime.now()
  debit_account_id: number = -1
  credit_account_id: number = -1
  amount: string = ""
  comment: string = ""
}


export const CommonTransactionForm = () => {
    const [accounts, setAccounts] = useState<AccountListGroup[]>([])
    const [formData, setFormData] = useState<TransactionForm>(new TransactionForm())
    const navigate = useNavigate()

    useEffect(() => {
      getAccountsList({token: getToken()}).then(res => {
            setAccounts(res)
        })
    }, [])

    const submit = (e: SyntheticEvent) => {
      e.preventDefault()
      console.log(formData)
      postTransaction({
        token: getToken(),
        requestBody: {
          debit_account_id: formData.debit_account_id,
          credit_account_id: formData.credit_account_id,
          amount: formData.amount,
          comment: formData.comment
        }
      }).then((res) => {
        navigate("/transaction")
      })
    }
    console.log(formData)
    return (
      <FormWrapper>
        <form>
          <FormInput
            label="Date"
            inputType="date"
            value={formData.date.toISODate() as string}
            onChange={e => setFormData({...formData, date: DateTime.fromISO(e.target.value)})} />

          <FormSelect label="Debit Account" value={formData.debit_account_id} onChange={(e) => setFormData({...formData, debit_account_id: Number(e.target.value)})}>
            <option value={-1}>Not Defined</option>
            {accounts.map(acc_group => (
              <optgroup key={acc_group.account_type} label={acc_group.title}>
                {acc_group.items?.map(item => (
                  <option key={item.id} value={item.id}>{item.name}</option>
                ))}
              </optgroup>
            ))}
          </FormSelect>

          <FormSelect label="Credit Account" value={formData.credit_account_id} onChange={(e) => setFormData({...formData, credit_account_id: Number(e.target.value)})}>
            <option value={-1}>Not Defined</option>
            {accounts.map(acc_group => (
              <optgroup key={acc_group.account_type} label={acc_group.title}>
                {acc_group.items?.map(item => (
                  <option key={item.id} value={item.id}>{item.name}</option>
                ))}
              </optgroup>
            ))}
          </FormSelect>

          <MonetaryInput
            onChange={v => setFormData({...formData, amount: v})}
            value={formData.amount}
            label='Amount' />
          
          <FormInput
            label="Comment"
            onChange={(e) => setFormData({...formData, comment: e.target.value})}
            value={formData.comment} />

          <div className="Submit-Block">
            <button
              disabled={
                formData.credit_account_id === -1 ||
                formData.debit_account_id === -1 ||
                !formData.amount
              }
              onClick={submit}
            >
              Submit
            </button>
          </div>

        </form>
      </FormWrapper>
    )
}