import './AuthPage.css';

import { SyntheticEvent, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { signinApiV1UserSigninPost as signinPost, SignInReq } from '../../client';
import account from '../../images/account.png';
import lock from '../../images/lock.png';
import { setToken } from '../../utils';

export const SignInPage = () => {
    const [data, setData] = useState<SignInReq>({username: "", password: ""})
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.removeItem("token")
    }, [])

    const submit = (e: SyntheticEvent) => {
        setLoading(true)
        e.preventDefault()
        signinPost(
            {requestBody: data}
        ).then(res => {
            setToken(res)
            setLoading(false)
            navigate("/dashboard")
        }).catch(err => {
            setError(String(err))
            setLoading(false)
        })
    }
    return(
        <div className="Auth-Page">
            {error && <span className="Error-Msg">{error}</span>}
            <div className="Auth-Form">
                <h1>Sign In</h1>
                <form>
                    <div className="Input-Group">
                        <img src={account} alt="" />
                        <input type="text" placeholder='Username' value={data.username} onChange={e => setData({...data, username: e.target.value})} />
                    </div>
                    <div className="Input-Group">
                        <img src={lock} alt="" />
                        <input type="password" placeholder='Password' value={data.password} onChange={e => setData({...data, password: e.target.value})} />
                    </div>
                    <button disabled={data.username === "" || data.password === "" || loading} onClick={submit}>Submit</button>
                    {loading && <span className="Loading-Msg">Loading...</span>}
                    <span className="Form-Info">Do not have an account yet? <Link to="/signup">Sign Up</Link></span>
                </form>
            </div>
        </div>
    )
}